import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme:{
      // bgColor:'',
      // fontColor:''
    },
    sysConfig:{},
    browser: (function(ua){
      var a = new Object();
      var b = {
        //micromessenger: /micromessenger/.test(ua),
        msie: /msie/.test(ua) && !/opera/.test(ua),
        opera: /opera/.test(ua),
        safari: /webkit/.test(ua) && !/chrome/.test(ua),
        firefox: /firefox/.test(ua),
        edge: /edge/.test(ua),
        trident: /trident/.test(ua),
        chrome: /chrome/.test(ua)
      };
      var vMark = "";
      for (var i in b) {
        if (b[i]) { vMark = i; break; }//"safari" == i ? "version" : i;
      }
      b.version = vMark && RegExp("(?:" + vMark + ")[\\/: ]([\\d.]+)").test(ua) ? RegExp.$1 : "0";
      b.ie = b.msie;
      b.ie6 = b.msie && parseInt(b.version, 10) == 6;
      b.ie7 = b.msie && parseInt(b.version, 10) == 7;
      b.ie8 = b.msie && parseInt(b.version, 10) == 8;
      
      //if(vMark == "trident") vMark = "ie11";
      
      a.b=vMark;
      a.v=b.version;
      a.m=/mobile/i.test(ua);
      a.wx=/MicroMessenger/i.test(ua);
      a.wxminiprogram=/miniProgram/i.test(ua);
      a.ios=/\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua);

      a.huawei=/huawei/i.test(ua);
      a.honor=/honor/i.test(ua);

      return a;
    })(window.navigator.userAgent.toLowerCase()),
  },
  getters: {
  },
  mutations: {
    updateTheme( state, n ) {
      state.theme = {};
      for( let k in n ){
        state.theme[k] = n[k];//.toString();
      }
    },
    setConfig( state, n ) {
      state.sysConfig = {};
      for( let k in n ){
        state.sysConfig[k] = n[k];//.toString();
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
