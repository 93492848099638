
import Network from './network';
// 封装各种接口请求
// export const 接口名 = () => Network.get('/路由',参数对象);
export default {
    // 登录
    login:(data) => Network.post('api/user/login',data),
    // 登录
    mobileLogin:(data) => Network.post('api/user/mobileLogin',data),
    // 注册
    register:(data) => Network.post('api/user/Register',data),
    //  获取注册页面配置
    GetRegisterPageConfig:(data) => Network.get('api/config/GetRegisterPageConfig',data),
    //验证公司、手机号
    checkphonecompany:(data) => Network.get('api/user/CheckPhoneOrCompany',data),
    // 找回密码
    modify:(data) => Network.post('api/user/modifyPwd',data),
    // 获取图文验证码
    getVierificationCode:() => Network.get('api/user/GetVierificationCode'),
    // 发送短信
    sendMobileCode:(data) => Network.post('api/user/SendRegisterCode',data),
    // 忘记密码
    forgetPwd:(data) => Network.post('api/user/ForgetPwd',data),
    // 验证短信
    ForgetPwdValidCode:(data) => Network.post('api/user/ForgetPwdValidCode',data),
    // 修改密码
    updatePwd:(data) => Network.post('api/user/UpdatePwd',data),
    // 获取用户信息
    getCurrentUserInfo:(data) => Network.get('api/user/GetCurrentUserInfo',data),
    // 系统配置
    config:() => Network.get('api/config/GetSysConfig'),
    // 获取注册协议
    getSysConfigToRegister:() => Network.get('api/user/GetSysConfigToRegister'),
    // 轮播图
    chartlist:(data) => Network.get('api/SysContentConfig/GetSysCarouselChartList',data),
    // 新闻公告
    newslist:(data) => Network.get('api/SysContentConfig/GetNewsList',data),
    // 底部导航信息
    navigationlist:(data) => Network.get('api/SysContentConfig/GetBottomNavigationsList',data),
    // 头部导航分类
    classify:() => Network.get('api/MallSlassification/GetMallSlassificationList'),
    // 分类购买
    category:(data) => Network.get('api/MallSlassification/SearchMallSlassification',data),
    // 分类下拉
    SearchMallSlassificationSelect:(data) => Network.get('api/MallSlassification/SearchMallSlassificationSelect',data),
    // 产品条件查询
    getCondition:(data) => Network.get('api/Prdt/GetPrdtBaseInfo',data),
    // 产品列表查询
    getProduct:(data) => Network.get('api/Prdt/GetPrdtList',data),

    
    // 搜索 产品条件查询
    getStandardCondition:(data) => Network.get('api/Standard/GetPrdtBaseInfo',data),
    // 搜索 产品列表查询
    getStandardProduct:(data) => Network.get('api/Standard/GetPrdtList',data),
    // 保存样式
    SavePrdtStyle:(data) => Network.post('api/config/SavePrdtStyle',data),
    
    // 产品详情
    getPrdtInfo:(data) => Network.get('api/Prdt/GetPrdtInfo',data),
    // 热词搜索
    getSearchRecordList:(data) => Network.get('api/Prdt/GetSearchRecordList',data),
    // 加入购物车
    addCart:(data) => Network.post('api/Cart/AddCart',data),
    // 修改购物车数量
    updateCartQty:(data) => Network.post('api/Cart/UpdateCartQty',data),
    // 修改购物车选中项
    updateCartCheck:(data) => Network.post('api/Cart/UpdateCartCheck',data),
    // 修改购物车选中项
    delCart:(data) => Network.post('api/Cart/DelCart',data),
    // 批量修改购物车选中项
    DelCartBatch:(data) => Network.post('api/Cart/DelCartBatch',data),
    // 获取购物车产品个数
    getCartNum:(data) => Network.get('api/Cart/GetCartNum',data),
    // 获取购物车产品集合
    getCartPrdtList:(data) => Network.get('api/Cart/GetCartPrdtList',data),
    // 获取结算页面产品集合
    getSettlePrdtList:(data) => Network.get('api/Cart/GetSettlePrdtList',data),
    // 重新计算运费 
    CalcFreightAmt:(data) => Network.post('api/Cart/CalcFreightAmt',data),
    // 加入购物车 判断库存 
    ValidQty:(data) => Network.get('api/Cart/ValidQty',data),
    // 获取报价单设置
    getQuotationConfig:(data) => Network.get('api/Quotation/GetQuotationConfig',data),
    // 生成图片并保存报价单
    SaveQuotation:(data) => Network.post('api/Quotation/SaveQuotation',data),
    // 导出文件，返回日期+文件名
    exportQuotation:(data) => Network.post('api/Quotation/ExportQuotation',data),
    // 下载图片
    downLoadImg:(data) => Network.post('api/Quotation/DownLoadImg',data),
    // 下载Excel
    downLoadExcel:(data) => Network.post('api/Quotation/DownLoadExcel',data),
    // 获取用户报价单
    getQuotationList:(data) => Network.get('api/Quotation/GetQuotationList',data),
    // 获取用户报价单详情
    getQuotationDetail:(data) => Network.get('api/Quotation/GetQuotationDetail',data),

    // 添加到货通知
    addCollection:(data) => Network.post('api/Collections/AddCollection',data),
    // 删除到货通知
    delCollection:(data) => Network.post('api/Collections/DelCollection',data),
    // 修改到货通知
    updateCollection:(data) => Network.post('api/Collections/UpdateCollection',data),
    // 添加到货通知
    getCollectionList:(data) => Network.get('api/Collections/GetPrdtList',data),
    // 获取服务器时间
    getCurrentDateTime:(data) => Network.get('api/user/GetCurrentDateTime',data),
    // 获取行政区域数据
    getDistrictById:(data) => Network.get('api/BuyerAddress/GetDistrictById',data),
    // 获取收货地址数据
    getUserAddressList:(data) => Network.get('api/BuyerAddress/GetUserAddress',data),
    // 获取收货地址数据 带分页
    getUserAddressListPage:(data) => Network.get('api/BuyerAddress/GetUserAddressPage',data),
    // 获取收货地址数据
    getUserAddressInfo:(data) => Network.get('api/BuyerAddress/GetUserAddressInfo',data),
    // 新增收货地址数据
    addBuyerAddress:(data) => Network.post('api/BuyerAddress/AddBuyerAddress',data),
    // 修改收货地址数据
    uptBuyerAddress:(data) => Network.post('api/BuyerAddress/UptBuyerAddress',data),
    // 删除收货地址数据
    delBuyerAddress:(data) => Network.post('api/BuyerAddress/DelBuyerAddress',data),
    // 设置默认收货地址
    setBuyerAddressDefault:(data) => Network.post('api/BuyerAddress/UptBuyerAddressDefault',data),
    // 提交订单
    submitOrder:(data) => Network.post('api/Order/SubmitOrder',data),
    // 订单列表
    getOrderList:(data) => Network.get('api/Order/GetBuyerOrders',data),
    // 订单明细
    getOrdersDetails:(data) => Network.get('api/Order/GetBuyerOrdersDetails',data),
    // 订单取消
    cancelOrder:(data) => Network.post('api/Order/CancelOrder',data),
    // 订单确认收货
    sureOrder:(data) => Network.post('api/Order/SureOrder',data),
    // 再来一单
    BuyAgain:(data) => Network.get('api/Order/BuyAgain',data),
    // 退货列表
    getReturnOrderList:(data) => Network.get('api/Order/GetBuyerReturnOrders',data),
    // 退货详情
    getReturnOrderDetail:(data) => Network.get('api/Order/GetReturnOrderDetail',data),
    // 申请退货
    applyReturnOrder:(data) => Network.post('api/Order/ApplyReturnOrder',data),
    // 获取退货原因
    getReturnReasonList:(data) => Network.get('api/Order/GetReturnReasonList',data),
    // 获取站内消息
    getMessageList:(data) => Network.get('api/Message/GetMessageList',data),
    // 设置为已读
    setRead:(data) => Network.post('api/Message/UpdateIsRead',data),
    // 删除站内消息
    delMessage:(data) => Network.post('api/Message/DelMessage',data),
    // 对账列表
    getAccountList:(data) => Network.get('api/Account/GetAccountList',data),
    // 对账明细
    getAccountInfo:(data) => Network.get('api/Account/GetAccountInfo',data),
    // 获取开票信息
    getInvoInfo:() => Network.get('api/user/GetInvoInfo'),
    // 更新开票信息
    updateInvo:(data) => Network.post('api/user/UpdateInvo',data),
    // 更新收票信息
    updateReceipt:(data) => Network.post('api/user/UpdateReceipt',data),
    // 刷新taoken
    replaceToken:(data) => Network.post('api/user/replaceToken',data),
    //判断背景色是否变更
    changeColor:(data) => Network.get('api/config/IfColorChange',data),
    // 获取广告位
    GetAdvertisement:(data) => Network.get('api/SysContentConfig/GetAdvertisement',data),
    
    //获取支付方式
    GetPaymentList:(data) => Network.post('api/Pay/GetPayInfo',data),
    //提交支付
    PayOrder:(data) => Network.post('api/Pay/ToOrder',data),
    //获取支付状态
    GetOrderPayStatus:(data) => Network.get('api/Pay/GetOrderPayStatus',data),
    
    //多个订单支付
    ToOrderList:(data) => Network.post('api/Pay/ToOrderList',data),
    //订单通知接口
    NotifyOrder:(data) => Network.get('api/Pay/NotifyOrder',data),

    //对账单支付
    ToPyaAccountInfo:(data) => Network.post('api/Pay/ToPyaAccountInfo',data),
    //对账单支付状态查询
    NotifyAccountInfo:(data) => Network.get('api/Pay/NotifyAccountInfo',data),
    
    //地址识别
    GetAddressSplit:(data) => Network.get('api/config/GetAddressSplit',data),
    
    //非标定制品相关接口
    AddCustomized:(data) => Network.post('api/customized/AddCustomized',data),
    //上传文件
    UploadForApi:(data) => Network.post('api/customized/UploadForApi',data),
    //获取非标订制品
    GetCustomizedList:(data) => Network.get('api/customized/GetCustomizedList',data),
    //获取非标订制品 详情
    GetCustomizedDetail:(data) => Network.get('api/customized/GetCustomizedDetail',data),

    //保存产品自定义编码
    SavePrdtCustom:(data) => Network.post('api/Prdt/Custom/SavePrdtCustom',data),
    //自定义编码查询
    GetPrdtCustomList:(data) => Network.get('api/Prdt/Custom/GetPrdtCustomList',data),
    //删除产品自定义编码
    DelPrdtCustom:(data) => Network.post('api/Prdt/Custom/DelPrdtCustom',data),

    //下载导入Excel模板
    DownLoadPrdtCustom:(data) => Network.get('api/Prdt/Custom/New/DownLoadPrdtCustom',data),
    //导入表数据Excel文件夹
    ImportPrdtCustom:(data) => Network.post('api/Prdt/Custom/ImportPrdtCustom',data),
    //导出文件，返回日期+文件名
    ExportPrdtCustom:(data) => Network.post('api/Prdt/Custom/New/ExportPrdtCustom',data),

    //导出我的物料，用来添加购物车
    ExportMyPrdtCustom:(data) => Network.post('api/Prdt/Custom/ExportMyPrdtCustom',data),
    //导入表数据Excel文件夹
    ImportCartPrdt:(data) => Network.post('api/Prdt/Custom/New/ImportCartPrdt',data),
    //批量加入购物车
    BachAddCart:(data) => Network.post('api/Cart/BachAddCart',data),
    //自定义编码购物车， 快速下单导入和查询后调用这个接口  返回所有自定义编码购物车的数据
    GetCartPrdtCustomList:(data) => Network.get('api/Prdt/Custom/GetCartPrdtCustomList',data),
    // 新增自定义编码到购物车，数量累加
    GetAddCartPrdtCustomList:(data) => Network.get('api/Prdt/Custom/New/GetAddCartPrdtCustomList',data),
    // 删除产品自定义编码
    DelCartPrdtCustom:(data) => Network.post('api/Prdt/Custom/DelCartPrdtCustom',data),
    // 编辑自定义编码购物车数量
    UpdateCartPrdtCustom:(data) => Network.post('api/Prdt/Custom/UpdateCartPrdtCustom',data),

    // 保存字段
    SaveTableSet:(data) => Network.post('api/user/SaveTableSet',data),
    GetTableSet:(data) => Network.get('api/user/GetTableSet',data),

    // 充值获取支付方式
    GetRechargePayInfo:(data) => Network.post('api/Pay/GetRechargePayInfo',data),
    // 充值
    ToRecharge:(data) => Network.post('api/Pay/ToRecharge',data),
    // 获取转入记录
    GetSurplusCheckPage:(data) => Network.get('api/user/GetSurplusCheckPage',data),
    // 充值-查询支付状态
    GetRechargePayStatus:(data) => Network.get('api/Pay/GetRechargePayStatus',data),
    // 设置支付密码
    SetPayPwd:(data) => Network.post('api/user/SetPayPwd',data),
    // 获取余额流水
    GetSurplusPage:(data) => Network.get('api/user/GetSurplusPage',data),
    // 校验支付密码是否正确
    CheckPayPwd:(data) => Network.post('api/user/CheckPayPwd',data),
    // 余额提现
    ToWithdrawal:(data) => Network.post('api/Pay/ToWithdrawal',data),
    
    // 校验支付密码是否正确
    CheckPayPwd:(data) => Network.post('api/user/CheckPayPwd',data),
    // 提现申请记录
    GetWithdrawalCheckPage:(data) => Network.get('api/user/GetWithdrawalCheckPage',data),

    // 货拉拉
    GetAccoutToken:(data) => Network.get('api/HuoLaLa/GetAccoutToken',data),
    GetUCityInfo:(data) => Network.get('api/HuoLaLa/GetUCityInfo',data),
    GetUOrderCalculate:(data) => Network.post('api/HuoLaLa/GetUOrderCalculate',data),
    GetUOrderRequest:(data) => Network.get('api/HuoLaLa/GetUOrderRequest',data),
    GetOrderCashier:(data) => Network.get('api/HuoLaLa/GetOrderCashier',data),
    GetOrderPayStatusForHll:(data) => Network.get('api/HuoLaLa/GetOrderPayStatus',data),

    // 埋点
    AddBuyerAct:(data) => Network.post('api/user/AddBuyerAct',data),
    // 自定义商品导出
    ExportPrdtList:(data) => Network.post('api/Prdt/ExportPrdtList',data),
    // 自定义商品导入
    ImportPrdtCustom:(data) => Network.post('api/Prdt/ImportPrdtCustom',data),

    // 投诉反馈=========
    //获取下拉框的值
    GetComplaintEnum:(data) => Network.get('api/Complaint/GetComplaintEnum',data),
    //分页获取数据
    GetComplaintPage:(data) => Network.get('api/Complaint/GetComplaintPage',data),
    //分页获取数据 详情
    GetComplaintInfo:(data) => Network.get('api/Complaint/GetComplaintInfo',data),
    //新增投诉反馈
    AddComplaint:(data) => Network.post('api/Complaint/AddComplaint',data),
    //删除投诉反馈
    DelComplaint:(data) => Network.post('api/Complaint/DelComplaint',data),


    //保存头像
    SetHeadImageUrl:(data) => Network.post('api/user/SetHeadImageUrl',data),

    //非标定制广告图模块 SourceType（1电脑端,2微信端,3小程序,4安卓端,5苹果端,6代理端）
    GetAdverModule:(data) => Network.get('api/SysContentConfig/GetAdverModule',data),

    //获取首页配置
    GetHomeTemplate:(data) => Network.get('api/config/GetHomeTemplate',data),
    // 子账号
    GetSubAccountList:(data) => Network.get('api/user/GetSubAccountList',data),
    // 子账号 新增
    AddSubAccount:(data) => Network.post('api/user/AddSubAccount',data),
    // 子账号 编辑
    EditSubAccount:(data) => Network.post('api/user/EditSubAccount',data),
    //银行支付
    PlaceOrder:(data) => Network.get('api/Pay/PlaceOrder',data),
}