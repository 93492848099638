
import Api from '../../api/index.js';
export default {
    install(Vue) {
        Vue.prototype.loadconfig = function (isLoad,callback){
					if(isLoad){
						Api.changeColor().then(res=>{
							if(res.data.status){
								let getChangeColor = localStorage.getItem('changeColor')?localStorage.getItem('changeColor'):false;
								// if(getChangeColor != res.data.data){
									Api.config().then(data=>{
										if(data.data.status){
											this.$store.commit('setConfig', data.data.data);
											localStorage.setItem('sysConfig',JSON.stringify(data.data.data));
											localStorage.setItem('title',data.data.data.baseConfig.webTitle)
											localStorage.setItem('lunbo',data.data.data.baseConfig.imgWebUrl)
											localStorage.setItem('upload',data.data.data.baseConfig.sp_Type_DefPic)
											localStorage.setItem('base',JSON.stringify(data.data.data.baseConfig))
											localStorage.setItem('tmpTheme', JSON.stringify(data.data.data.colorConfig));
											this.$store.commit('updateTheme', data.data.data.colorConfig);
											callback&&callback(data.data.data);
										} 
									})
								// }

								localStorage.setItem('changeColor',res.data.data)
							} 
						})
					} else {
						let sysConfigStr = localStorage.getItem('sysConfig');
						if(sysConfigStr){
								let sysConfig = JSON.parse(sysConfigStr)

								this.$store.commit('setConfig', sysConfig);
								localStorage.setItem('sysConfig',JSON.stringify(sysConfig));
								localStorage.setItem('title',sysConfig.baseConfig.webTitle)
								localStorage.setItem('lunbo',sysConfig.baseConfig.imgWebUrl)
								localStorage.setItem('upload',sysConfig.baseConfig.sp_Type_DefPic)
								localStorage.setItem('base',JSON.stringify(sysConfig.baseConfig))
								localStorage.setItem('tmpTheme', JSON.stringify(sysConfig.colorConfig));
								this.$store.commit('updateTheme', sysConfig.colorConfig);
								callback&&callback(sysConfig);
						}else{
								Api.config().then(res=>{
										if(res.data.status){
												this.$store.commit('setConfig', res.data.data);
												localStorage.setItem('sysConfig',JSON.stringify(res.data.data));
												localStorage.setItem('title',res.data.data.baseConfig.webTitle)
												localStorage.setItem('lunbo',res.data.data.baseConfig.imgWebUrl)
												localStorage.setItem('upload',res.data.data.baseConfig.sp_Type_DefPic)
												localStorage.setItem('base',JSON.stringify(res.data.data.baseConfig))
												localStorage.setItem('tmpTheme', JSON.stringify(res.data.data.colorConfig));
												this.$store.commit('updateTheme', res.data.data.colorConfig);
												callback&&callback(res.data.data);
										}
								})
						}
					}
					
					
        };
        //行为埋点
				Vue.prototype.setBehaviorPoint = function (json){
					//return false;//埋点先关掉
					//浏览器
					var browser = ''
					var userAgent = window.navigator.userAgent; //取得浏览器的userAgent字符串
					console.log(userAgent)
					var isOpera = userAgent.indexOf("Opera") > -1;
					if (isOpera) {//判断是否Opera浏览器
						browser= "Opera"
					}; 
					if (userAgent.indexOf("Firefox") > -1) {//判断是否Firefox浏览器
						browser= "FF";
					} 
					if (userAgent.indexOf("Chrome") > -1){//判断是否Chrome浏览器
						browser= "Chrome";
					}
					if (userAgent.indexOf("Safari") > -1&&userAgent.indexOf("Chrome")==-1) {//判断是否Safari浏览器
						browser= "Safari";
					} 
					if (userAgent.indexOf("Edg") > -1) {//判断是否微软浏览器
						browser= "Edg";
					} 
					if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {//判断是否IE浏览器
						browser= "IE";
					}; 
					
					let tmpParams = {
						ViewPath: '',
						ViewName: '',
						Timer: '',
						BrowserType: browser,
					}
					tmpParams = Object.assign(tmpParams, json);
					//_this.$toast(tmpParams)
					Api.AddBuyerAct(tmpParams).then(res=>{

					})
				};
				//计算 解决js运算bug
				Vue.prototype.mathcalc = function (a, b, mode = '+'){
      
					if( mode == '-' ){//=========================
						let c, d, e;
						try {
							c = a.toString().split('.')[1].length;
						} catch (f) {
							c = 0;
						}
						try {
							d = b.toString().split('.')[1].length;
						} catch (f) {
							d = 0;
						}
						return e = Math.pow(10, Math.max(c, d)), (this.mathcalc(a, e, '*') - this.mathcalc(b, e, '*')) / e;
					} else if( mode == '*' ){//=========================
						let c = 0,
						d = a.toString(),
						e = b.toString();
						try {
							c += d.split('.')[1].length;
						} catch (f) {}
						try {
							c += e.split('.')[1].length;
						} catch (f) {}
						return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c);
					} else if( mode == '/' ){//=========================
						let c, d, e = 0,f = 0;
						try {
							e = a.toString().split('.')[1].length;
						} catch (g) {}
						try {
							f = b.toString().split('.')[1].length;
						} catch (g) {}
						return c = Number(a.toString().replace('.', '')), d = Number(b.toString().replace('.', '')), this.mathcalc(c / d, Math.pow(10, f - e), '*');
					} else {//=========================
						let c, d, e;
						try {
							c = a.toString().split('.')[1].length;
						} catch (f) {
							c = 0;
						}
						try {
							d = b.toString().split('.')[1].length;
						} catch (f) {
							d = 0;
						}
						return e = Math.pow(10, Math.max(c, d)), (this.mathcalc(a, e, '*') + this.mathcalc(b, e, '*')) / e;
					}
				};
  }
}